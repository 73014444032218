<template>
  <div class="leaderboards">
    <h1 align="Center" class="pa-2">Script Leaderboards</h1>

    <v-container class="my-5">
      <v-layout row>
        <v-flex v-for="leaderboard in leaderboards" :key="leaderboard.name">
          <v-col cols="auto">
            <v-container>
              <v-card
                elevation="9"
                class="pa-2"
                min-height="80%"
                max-width="500"
              >
                <v-card-text
                  align="center"
                >
                  <h1>{{ leaderboard.name }}</h1>
                </v-card-text>
                 <v-responsive align="Center"
                  ><v-img max-height="400" max-width="400" :src="leaderboard.img">
                  </v-img
                ></v-responsive>
                <v-row dense justify="center">
                  <v-card-action align="center"
                    ><v-btn class="ma-2" flat :to="leaderboard.leaderboard"
                      >View Leaderboard</v-btn
                    ></v-card-action
                  >
                </v-row>
              </v-card>
            </v-container>
          </v-col>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      leaderboards: [
        {
          name: "SkrrtBarCrawler",
          leaderboard: "/crawler",
          img: "/barcrawler.png",
        },
        {
          name: "SkrrtPicker",
          leaderboard: "/picker",
          img: "/picker.png",
        },
        {
          name: "SkrrtChatter",
          leaderboard: "/chatter",
          img: "/chatter.png",
        },
      ],
    };
  },
};
</script>
