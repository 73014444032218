<template>
    <v-app-bar app color="primary"><v-toolbar-title>SkrrtScripts</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
            v-for="item in links"
            :key="item.text"
            :to="item.route"
            flat
            color="secondary"
            class="hidden-sm-and-down">{{ item.text }}
        </v-btn>
        <v-app-bar-nav-icon class="hidden-sm-and-up" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </v-toolbar-items>
      <v-navigation-drawer app right temporary v-model="drawer">
        <v-list>
          <v-list-item v-for="item in links" :key="item.text">
            <v-list-item-content>
              <v-list-item-title router="{{item.route}}">{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      drawer: null,
      links: [
        {text: "Home", route: "/"},
        {text: "Scripts", route: "/scripts"},
        {text: "Leaderboards", route: "/leaderboards"},
      ],
    };
  },
};
</script>