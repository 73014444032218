<template>
  <div class="scripts">
    <h1 align="Center" class="pa-2">Scripts</h1>
    <p align="center" class="pa-2">
      This is the collection of scripts I've written and published at Tribot to
      date. If you have any suggestions or improvements for any of my scripts
      please feel free to contact me via any of the links on the front page.
    </p>
    <v-container class="my-5">
      <v-layout row>
        <v-flex v-for="script in scripts" :key="script.name">
          <v-col cols="auto">
            <v-container>
              <v-card
                elevation="9"
                class="pa-2"
                min-height="100%"
                max-width="500"
              >
                <v-responsive align="Center"
                  ><v-img max-height="400" max-width="400" :src="script.img">
                  </v-img
                ></v-responsive>
                <v-card-text
                  align="center"
                  style="overflow-y: auto; height: 295px"
                >
                  <h1>{{ script.name }}</h1>
                  <p class="ma-6">Version: {{ script.version }}</p>
                  <h3>Description:</h3>
                  <p class="mt-2">{{ script.blurb }}</p>
                </v-card-text>
                <v-row justify="center">
                  <v-col cols="auto">
                    <v-card-actions>
                      <v-btn link :href="script.thread_link">
                        Thread Link
                      </v-btn>
                    </v-card-actions>
                  </v-col>
                  <v-col cols="auto">
                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-btn link :href="script.repo_link"> Repo Link </v-btn>
                    </v-card-actions>
                  </v-col>
                  <v-col cols="auto">
                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-btn router :to="script.leaderboard"> Leaderboard </v-btn>
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-card>
            </v-container>
          </v-col>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>



<script>
export default {
  data() {
    return {
      scripts: [
        {
          name: "SkrrtBarCrawler",
          img: "/barcrawler.png",
          blurb:
            "SkrrtBarCrawler completes the Alfred Grimhand's Barcrawl - a prerequisite for the Horror from the Deep and Scorpion Catcher quests. Completion also allows for smashing vials upon drinking the last dose, which is useful/convenient when you're trying to save inventory space while bossing.",
          version: "1.8",
          thread_link:
            "https://community.tribot.org/topic/83811-open-source-ge-no-reqs-skrrtbarcrawler/",
          repo_link: "https://repo.tribot.org/script/id/3855-skrrtbarcrawler/",
          leaderboard: "/crawler",
        },
        {
          name: "SkrrtPicker",
          img: "/picker.png",
          blurb:
            "SkrrtPicker is a new type of script that allows for a fully customisable approach to picking up/collecting items and ground items.",
          version: "0.03",
          thread_link:
            "https://community.tribot.org/topic/83823-p2pf2p-0-reqs-open-source-skrrtpicker/",
          repo_link: "https://repo.tribot.org/script/id/3861-skrrtpicker/",
          leaderboard: "/picker",
        },
        {
          name: "SkrrtChatter",
          img: "/chatter.png",
          blurb:
            "SkrrtChatter is a simple yet powerful script that allows you to traverse to most places in Gielinor and interact with NPC's.",
          version: "0.8",
          thread_link:
            "https://community.tribot.org/topic/83774-open-source-easy-to-use-free-skrrtchatter/",
          repo_link: "https://repo.tribot.org/script/id/3846-skrrtchatter/",
          leaderboard: "/chatter",
        },
      ],
    };
  },
};
</script>