import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '/src/views/Dashboard.vue'
import Leaderboards from '/src/views/Leaderboards.vue'
import Crawler from '/src/views/Crawler.vue'
import Picker from '/src/views/Picker.vue'
import Chatter from '/src/views/Chatter.vue'

import Scripts from '/src/views/Scripts.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard
},
{
    path: '/leaderboards',
    name:'leaderboards',
    component: Leaderboards
},
{
    path: '/scripts',
    name:'scripts',
    component: Scripts
},
{
  path: '/crawler',
  name:'crawler',
  component: Crawler
},
{
  path: '/picker',
  name:'picker',
  component: Picker
},
{
  path: '/chatter',
  name:'chatter',
  component: Chatter
}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
