<template>

  <v-footer absolute padless>
      <v-row align="center">
      <v-col cols="10" align="left" class="ma-3">
        {{ new Date().getFullYear() }} — <strong>SkrrtScripts</strong>
      </v-col>
      <v-col cols="auto">
        <v-switch :value="darkMode" @change="toggleDarkMode" :label="`${switchLabel} mode`"></v-switch>
      </v-col>
      </v-row>
  </v-footer>

</template>

<script>
export default {
  data: () => ({
    darkMode: false
  }),
  methods: {
    toggleDarkMode: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.darkMode = !this.darkMode;
    }
  },
  computed: {
    switchLabel: function () {
      return this.darkMode ? 'light' : 'dark';
    }
  }
};

</script>

<style scoped>

</style>