import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';


Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#df682e',
                secondary: '#e6916b'
            },
            dark: {
                primary: '#df682e',
                secondary: '#e6916b'
            },
        },
    },
});
