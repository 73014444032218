<template>
  <v-app>
    <v-container>
      <v-row align="center" justify="center">
        <v-col >
      <h1 align="center" class="pa-16">Something hot is coming soon...</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col  align="center">
        <v-btn  x-large color="#7289DA" href="https://discord.gg/3e6gYpTYEk">Join Discord</v-btn>
        </v-col>
      </v-row>

    </v-container>

    <!--    <iframe src="https://discord.com/widget?id=868542473033642024&theme=dark" align="right" width="250" height="300"-->
    <!--                          allowtransparency="true" frameborder="0"-->
    <!--                          sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>-->
  </v-app>
</template>


<script>
export default {

  data() {
    return {}
  }


};

</script>
<style>
.wrapper-buttons {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 250px;
}
</style>