<template>
  <div class="crawler">
    <h1 align="Center" class="pa-2">Chatter</h1>
    <v-data-table :headers="headers" :items="items"></v-data-table>
    <h1 align="Center"></h1>
  </div>
</template>

<script>
import axios from "axios";
import humanizeDuration from 'humanize-duration'

export default {
  data() {
    return {
      headers: [
        {
          text: "Username",
          align: "start",
          value: "_id",
        },
        {
          text: "Runtime",
          value: "runtime",
        },
        {
          text: "Interactions",
          value: "interactions",
        },
      ],
      items: [],
    };
  },
  mounted: function () {
    axios.get("https://api.skrrtscripts.com/api/scripts/chatter")
        .then((response) => {
          this.items = response.data.map(data => {
            return {
              ...data,
              runtime: humanizeDuration(data.runtime, {round: true})
            }
          });

          console.log(this.items)
        }).catch((error) => console.log(error));
  },
};
</script>