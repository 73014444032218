<template>
  <v-app>
    <Navigation>
    </Navigation>
    <v-main>
      <v-container fluid align="center">
        <router-view></router-view>
      </v-container>
      <Footer></Footer>
    </v-main>
  </v-app>
</template>

<script>
import Navigation from "./components/Navigation";
import Footer from "@/components/Footer";

document.title = "SkrrtScripts";
export default {
  name: "App",

  components: {
    Navigation,
    Footer,
  },

  data: () => ({
    //
  }),
};
</script>
<style>

</style>